/* MarkdownStyles.css */
.markdown-container {
  --base-font: 'Inter', system-ui, -apple-system, sans-serif;
  --code-font: 'Fira Code', Consolas, Monaco, monospace;

  /* Shared theme properties */
  --radius-sm: 4px;
  --radius-md: 8px;
  --transition: 0.2s ease-in-out;
}

/* Light theme */
.light-theme {
  --text-primary: #1a1a1a;
  --text-secondary: #4a4a4a;
  --background-primary: #ffffff;
  --background-secondary: #f8f9fa;
  --accent-primary: #2563eb;
  --accent-secondary: #1d4ed8;
  --border-primary: #e5e7eb;
  --border-secondary: #d1d5db;
  --code-background: #f3f4f6;
  --table-header-bg: #f1f5f9;
  --table-border: #e5e7eb;
  --table-zebra: #f8fafc;
  --blockquote-bg: #f3f4f6;
  --blockquote-border: #2563eb;
  --shadow-sm: 0 1px 3px rgba(0,0,0,0.05);
  --image-border: rgba(0,0,0,0.1);
}

/* Dark theme */
.dark-theme {
  --text-primary: #f8fafc;
  --text-secondary: #e2e8f0;
  --background-primary: #0f172a;
  --background-secondary: #1e293b;
  --accent-primary: #60a5fa;
  --accent-secondary: #3b82f6;
  --border-primary: #334155;
  --border-secondary: #475569;
  --code-background: #1e293b;
  --table-header-bg: #1e293b;
  --table-border: #334155;
  --table-zebra: #1e293b;
  --blockquote-bg: #1e293b;
  --blockquote-border: #60a5fa;
  --shadow-sm: 0 1px 3px rgba(255,255,255,0.05);
  --image-border: rgba(255,255,255,0.1);
}

.markdown {
  font-family: var(--base-font);
  line-height: 1.75;
  color: var(--text-primary);
  max-width: 90%;
  margin: 0 auto;
  /*padding: 1rem 0.5rem;*/
}

/* ======== Headings ======== */
.markdown h1, .markdown h2, .markdown h3, .markdown h4, .markdown h5, .markdown h6 {
  font-weight: 600;
  margin: 2.5rem 0 1.5rem;
  position: relative;
  scroll-margin-top: 2rem;
}

.markdown h1 {
  font-size: 2.5rem;
  letter-spacing: -0.025em;
  border-bottom: 2px solid var(--accent-primary);
  padding-bottom: 0.75rem;
}

.markdown h2 {
  font-size: 2rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--border-primary);
}

.markdown h3 { font-size: 1.5rem; }
.markdown h4 { font-size: 1.25rem; }
.markdown h5 { font-size: 1.125rem; }
.markdown h6 { font-size: 1rem; color: var(--text-secondary); }

/* ======== Code Blocks ======== */
.code-block {
  position: relative;
  margin: 2.5rem 0;
  border-radius: var(--radius-md);
  overflow: hidden;
  box-shadow: var(--shadow-sm);
  background: var(--code-background);
}

.code-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1.25rem;
  background-color: var(--background-secondary);
  border-bottom: 1px solid var(--border-primary);
}

.language-tag {
  font-family: var(--code-font);
  font-size: 0.875rem;
  color: var(--text-secondary);
  font-weight: 500;
}

.copy-button {
  background: var(--background-primary);
  border: 1px solid var(--border-primary);
  padding: 0.375rem 0.75rem;
  border-radius: var(--radius-sm);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: var(--transition);
}

.copy-button:hover {
  background: var(--accent-primary);
  border-color: var(--accent-secondary);
  color: white;
}

.copy-icon {
  width: 18px;
  height: 18px;
}

/* ======== Tables ======== */
.table-container {
  overflow-x: auto;
  margin: 2.5rem 0;
  border-radius: var(--radius-md);
  box-shadow: var(--shadow-sm);
  border: 1px solid var(--border-primary);
}

.markdown table {
  width: 100%;
  border-collapse: collapse;
  background: var(--background-primary);
  min-width: 600px;
}

.markdown th {
  background: var(--table-header-bg);
  padding: 1rem 1.25rem;
  font-weight: 600;
  text-align: left;
  border-bottom: 2px solid var(--table-border);
  color: var(--accent-primary);
}

.markdown td {
  padding: 1rem 1.25rem;
  border-bottom: 1px solid var(--table-border);
  color: var(--text-primary);
}

.markdown tr:nth-child(even) {
  background: var(--table-zebra);
}

.markdown tr:hover {
  background: var(--background-secondary);
}

/* ======== Video Embeds ======== */
.video-container {
  position: relative;
  width: 35vw;
  margin: 2.5rem 0;
  border-radius: var(--radius-md);
  overflow: hidden;
  box-shadow: var(--shadow-sm);
  background: var(--background-secondary);
  aspect-ratio: 16/9;
}

.youtube-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

/* Optional: Add hover effect */
.video-container:hover {
  transform: translateY(-3px);
  transition: transform var(--transition);
}

/* ======== Images ======== */

.image-container {
  margin: 2.5rem 0;
  cursor: zoom-in;
  position: relative;
  border-radius: var(--radius-md);
  overflow: hidden;
  transition: transform var(--transition);
  width: 30vw;
    height: auto;
}

.image-container:hover {
  transform: translateY(-4px);
}

.image-wrapper {
  position: relative;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  background: var(--background-secondary);
}

.image-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: var(--background-secondary);
  transition: opacity 0.3s ease;
}

.image-skeleton {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
          90deg,
          var(--background-secondary) 25%,
          var(--border-primary) 50%,
          var(--background-secondary) 75%
  );
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  from { background-position: 200% 0; }
  to { background-position: -200% 0; }
}

.image-error {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--text-secondary);
  padding: 1rem;
  background: var(--background-primary);
  border-radius: var(--radius-sm);
}

.image-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background: linear-gradient(0deg, rgba(0,0,0,0.7) 0%, transparent 100%);
  color: white;
  font-size: 0.9rem;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-caption.visible {
  opacity: 1;
}

/* ======== Lightbox Styles ======== */
.lightbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(10px);
}

.lightbox-content {
  max-width: 90%;
  max-height: 90%;
  position: relative;
}

.lightbox-image {
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
  border-radius: var(--radius-md);
}

.lightbox-caption {
  text-align: center;
  color: white;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background: rgba(0,0,0,0.7);
  border-radius: var(--radius-sm);
}

.lightbox-close {
  position: absolute;
  top: -40px;
  right: 0;
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  padding: 0.5rem;
}

.lightbox-close:hover {
  opacity: 0.8;
}

/* ======== Blockquotes ======== */
.styled-blockquote {
  margin: 2.5rem 0;
  padding: 2rem 2.5rem;
  background: var(--blockquote-bg);
  border-left: 4px solid var(--blockquote-border);
  border-radius: var(--radius-md);
  position: relative;
}

.styled-blockquote p {
  color: var(--text-primary);
  font-size: 1.125rem;
  line-height: 1.75;
  margin: 0;
}

/* ======== Lists ======== */
.markdown ul, .markdown ol {
  padding-left: 2rem;
  margin: 1.5rem 0;
}

.markdown li {
  margin: 0.75rem 0;
  padding-left: 1rem;
  position: relative;
  color: var(--text-primary);
}

.markdown ul li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0.5rem;
  width: 6px;
  height: 6px;
  background: var(--accent-primary);
  border-radius: 50%;
}

.markdown ol {
  counter-reset: list-counter;
}

.markdown ol li {
  counter-increment: list-counter;
  padding-left: 2rem;
}

.markdown ol li::before {
  content: counter(list-counter) ".";
  position: absolute;
  left: 0;
  color: var(--accent-primary);
  font-weight: 600;
}

/* ======== Links ======== */
.markdown-link {
  color: var(--accent-primary);
  text-decoration: none;
  font-weight: 500;
  border-bottom: 1.5px solid currentColor;
  transition: opacity var(--transition);
}

.markdown-link:hover {
  opacity: 0.8;
}

/* ======== Horizontal Rules ======== */
.markdown hr {
  border: 0;
  height: 1px;
  background: var(--border-primary);
  margin: 3rem 0;
  position: relative;
}

.markdown hr::after {
  content: '⋆';
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--background-primary);
  padding: 0 1rem;
  color: var(--text-secondary);
}

/* ======== Responsive Design ======== */
@media (max-width: 768px) {
  .markdown {
    padding: 1.5rem 1rem;
    font-size: 1rem;
  }

  .markdown h1 {
    font-size: 2rem;
  }

  .table-container {
    border-radius: 0;
    border-left: none;
    border-right: none;
  }

  .code-block {
    margin: 1.5rem -1rem;
    border-radius: 0;
  }
}