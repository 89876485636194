/* Loader.css */
.neu-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #0f172a;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  overflow: hidden;
}

.neu-grid-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image:
          linear-gradient(rgba(99, 102, 241, 0.05) 1px, transparent 1px),
          linear-gradient(90deg, rgba(99, 102, 241, 0.05) 1px, transparent 1px);
  background-size: 24px 24px;
}

.neu-core {
  position: relative;
  margin-bottom: 4rem;
}

.neu-orbital {
  position: relative;
  z-index: 2;
  filter: drop-shadow(0 0 16px rgba(79, 70, 229, 0.4));
}

.neu-connection-pulse {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200%;
  height: 200%;
  border: 2px solid #6366f1;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: neuPulse 3s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.neu-message-stack {
  text-align: center;
  margin: 2rem 0;
  position: relative;
  z-index: 2;
}

.neu-message-primary {
  color: #f8fafc;
  font-size: 1.75rem;
  font-weight: 600;
  letter-spacing: -0.025em;
  margin-bottom: 0.5rem;
  animation: neuTextSlide 0.8s ease-out;
}

.neu-message-secondary {
  color: #94a3b8;
  font-size: 1.1rem;
  font-weight: 400;
  max-width: 500px;
  margin: 0 auto;
}

.neu-progress {
  width: min(90%, 400px);
  margin-top: 3rem;
}

.neu-progress-track {
  height: 4px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  overflow: hidden;
}

.neu-progress-bar {
  height: 100%;
  background: linear-gradient(90deg, #6366f1 0%, #4f46e5 100%);
  transition: width 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}

.neu-progress-status {
  color: #64748b;
  font-size: 0.75rem;
  text-align: center;
  margin-top: 0.75rem;
  font-family: 'IBM Plex Mono', monospace;
}

.neu-particle-field {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.neu-particle {
  position: absolute;
  background: radial-gradient(circle, #4f46e5 0%, transparent 70%);
  animation: neuParticleFloat 12s infinite linear;
}

.neu-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 2rem;
  width: 100%;
}

.neu-logo img {
  max-width: 100%;
  height: auto;
}

.powered-by {
  color: #94a3b8;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  text-align: center;
}

@keyframes neuPulse {
  0% { opacity: 0.3; transform: translate(-50%, -50%) scale(0.8); }
  50% { opacity: 0.1; transform: translate(-50%, -50%) scale(1.2); }
  100% { opacity: 0; transform: translate(-50%, -50%) scale(1.6); }
}

@keyframes neuTextSlide {
  0% { opacity: 0; transform: translateY(10px); }
  100% { opacity: 1; transform: translateY(0); }
}

@keyframes neuParticleFloat {
  from { transform: translateY(100vh) rotate(0deg); }
  to { transform: translateY(-100vh) rotate(720deg); }
}