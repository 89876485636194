@tailwind base;
@tailwind components;
@tailwind utilities;


body.loading * {
  transition: none !important;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  height: 100%;
  box-sizing: border-box;
  touch-action: manipulation;
}

body {
  position: relative;
  min-height: 100%;
  margin: 0;
}

html,
body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-x: none;
}

li {
  list-style: none;
}

input:focus {
  outline: none;
}

// textarea:focus {
//   outline: none;
// }

.div-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 600px;

  margin: auto;
}
.drawer-dimensions {
  width: auto;
  height: 30vh;
}


.wordBreak {
  word-break: break-all;
}

.user-avatar {
  width: 30px;
  border-radius: 50%;
}

.header-section {
  height: 50px;
}

.header-section .header-height {
  height: 50px;
}

.body-section {
  height: calc(100dvh - 50px);
  padding: 50px;
  overflow: hidden;
  background: aliceblue;
}

.chat-container {
  padding: 10px;
  width: 100%;
  height: 100dvh;
  max-height: 100dvh;
}

.chat-faq-button {
  position: absolute;
  right: 32px;
  top: 130px;
}

.faq-chat-hide {
  opacity: 0;
}

.faq-chat {
  position: absolute;
  background: #fff;
  right: 125px;
  margin-top: 30px;
}

.faq-chat.show {
  display: block;
}

.faq-chat.hide {
  display: none;
}

.selected-category {
  position: absolute;
  right: 34px;
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  margin-top: -20px;
}

.addmin-sidebar {
  border: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  background: #fff;
}

.addmin-container {
  width: 100%;
  height: calc(100dvh - 200px);
  overflow: auto;
}

.custom-select {
  max-width: 70vw;
  padding: 10px;
  border-radius: 5px;
  border: none;
  color: #333;
  font-size: 16px;
  transition: all 0.3s ease;
}

.custom-select:hover {

}

.custom-select:focus {
  outline: none;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.custom-select option {
  color: #333;

}

@keyframes border-flicker {
  0% { border-color: #ff0000; }
  50% { border-color: #00ff00; }
  100% { border-color: #0000ff; }
}

// textarea::placeholder {
//   text-align: center;
//   font-size: 18px;
//   font-style: italic;
// }

.ai-model {
  position: absolute;
  width: 100%;
  height: 100dvh;
  top: 0px;
  left: 0px;
  z-index: 999;
}

.ai-model .ai-model-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
}

.ai-model .ai-model-content {
  min-width: 500px;
  min-height: 200px;
  border-radius: 5px;
  max-height: calc(100dvh - 100px);
  display: block;
  overflow: auto;

  &.m-md {
    min-width: 1020px;
  }
}

// ===========  MultiSelect =========
.ai-multiselect {
  .selected-pils {
    padding: 5px 5px;
    font-size: 12px;
    background: #ccc;
    margin: 0px 2px;
    border-radius: 5px;
  }

  .ms-selected-box {
    width: 500px;
    overflow: auto;
    white-space: nowrap;
  }

  .ms-selected-box::-webkit-scrollbar {
    display: none;
  }

  .selected-pils-remove {
    cursor: pointer;
  }

  .show-option-btn {
    border-left: 1px solid #ccc;
    padding: 5px;
    background: #ccc;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.multiselect-options-container {
  display: none;
  transition: all ease-in-out 0.3s;

  &.show {
    transform: scale(1, 1);
    display: block;
  }
}

// ===========  MultiSelect End =========
.select-dropdown {
  position: relative;
  display: inline-block;
  width: 100%;

  &__button {
    padding: 10px 10px;
    width: 100%;
    font-size: 14px;
    cursor: pointer;
    text-align: left;
    min-height: 43px;

    &::focus {
      outline: none;
    }

    .zmdi-chevron-down {
      position: absolute;
      right: 10px;
      top: 42px;
    }
  }

  &__list {
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    max-height: 300px;
    overflow: auto;
    margin: 2px 0px;
    padding: 0;
    list-style-type: none;
    opacity: 0;
    pointer-events: none;
    transform-origin: top left;
    transform: scale(1, 0);
    transition: all ease-in-out .3s;
    z-index: 2;

    &.active {
      opacity: 1;
      pointer-events: auto;
      transform: scale(1, 1);

    }
  }

  &__list-item {
    display: block;
    list-style-type: none;
    padding: 10px 10px;
    background: #fff;
    border-top: 1px solid #e6e6e6;
    font-size: 14px;
    line-height: 1.4;
    cursor: pointer;
    color: #616161;
    transition: all ease-in-out .3s;
  }
}

.select-box {
  &.secondry {
    button {
      color: #ffff;
    }
  }

  &.small-select {
    button {
      min-height: 25px;
      min-width: 100px;
      font-size: 10px;
      height: 25px;
      padding: 2px 7px;
    }

    i {
      top: 12px
    }
  }
}

.user-detail {
  display: none;
  position: absolute;
  right: 10px;
  background: #fff;
  padding: 15px;
  margin-top: 15px;
  z-index: 9999;

  &.active {
    display: block;
  }
}

.body-container {
  height: calc(100dvh - 50px);
  overflow: hidden;
  background: aliceblue;
}

.chat-options-section {
  height: calc(100dvh - 200px);
}

//  =================  Auth layout  =================

.auth-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
}

.auth-container {
  min-height: 405px;
  display: flex;
  border: 1px solid #ccc;
  border-radius: 10px;
  background: #fff;

  .auth-container-sidebar-left {
    width: 400px;
    justify-content: center;
    align-items: center;
    flex-direction: column;

  }

  .auth-container-sidebar-right {
    width: 400px;
  }
}

//  ==================== Admin Section =================
.admin-side-menu {
  height: calc(100dvh - 50px);
  overflow: hidden;
  min-width: 300px;
}

.primary-menu {
  li {
    padding: 10px;
    border-bottom: 1px solid #fff;
    color: #fff;
  }
}

.notification-model {
  position: absolute;
  width: 0px;
  height: 70px;
  top: 60px;
  right: 10px;
  z-index: 9999;
  border-radius: 5px;
  display: none;

  &.active {
    display: block;
    min-width: 300px;
    width: auto;
  }
}

.ReactModal__Overlay {
  z-index: 999;
}

.custom-model {
  padding: 0px;
  border: none;
  position: absolute;
  left: 50%;
  top: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);

  &.model-md {
    width: 50%;
  }

  &.model-xs {
    width: 20%;
  }

  &.model-lg {
    width: 80%;
  }
}

.organisation-selector-box {
  position: absolute;
  background: #fff;
  margin-top: 10px;
  display: none;

  &.active {
    display: block;
  }
}




.topup-box {
  &.active {
    color: #fff;
  }
}

.left-sidebar-section {
  min-width: 20%;
  height: calc(100dvh - 50px);
  overflow: hidden;
  z-index: 999;
  top: 50px;
  position: absolute;
  width: 20%;
}

@media (min-width: 768px) {
  .left-sidebar-section {
    height: 100dvh;
    position: relative;
    top: 0px;
  }
}

.organisation-image-section {
  border: 1px solid #ccc;
  border-radius: 50%;
  padding: 5px;
  display: block;

  &.dark {
    border: 1px solid #fff;
  }
}

.organisation-setting-menu {
  position: absolute;
  width: 300px;
  overflow: hidden;
  height: auto;
  display: none;
  padding: 8px;

  &.active {
    display: block;
  }
}

//  =================  Primary Loader  =================
@keyframes barWidth {
  0% {
    width: 0%;
  }

  25% {
    width: 50%;
  }

  50% {
    width: 100%;
  }

  75% {
    width: 50%;
  }

  100% {
    width: 0%;
  }
}

@keyframes barWidth2 {
  0% {
    width: 0%;
  }

  50% {
    width: 50%;
  }

  100% {
    width: 100%;
  }
}

.horizontal-bar-wrap {
  height: 4px;
  width: 100%;

  .bar {
    position: relative;
    width: 0%;
    height: 100%;
    margin: 0 auto;
    animation: barWidth;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    &.bar1 {
      animation-delay: 0s;
      background: #02A09A;
      top: 0;
      z-index: 1;
    }
  }
}

.chat-category-menu-section {
  height: 100%;
  overflow: auto;
  max-height: 33.5vh;

  &.with-matrix {
    height: 100%;
  }
}

.chat-category-menu-section::-webkit-scrollbar {
  display: none;
}

.chat-category-menu-section-full-height {
  height: 100%;
  overflow: auto;
  max-height: 70vh;

  &.with-matrix {
    height: 100%;
  }
}

.chat-category-menu-section-full-height::-webkit-scrollbar {
  display: none;
}


.spent-matrix {
  border: 1px solid #fff;
  margin: 5px;
}

.spent-matrix-body {
  height: 50px;
  overflow: auto;
}
.spent-matrix-body::-webkit-scrollbar {
  display: none;
}

.spniner-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
  .primary-spinner {
    width: 100px;
    height: 100px;
    margin: 0;
    background: transparent;
    border-top: 4px solid #1f2937;
    border-right: 4px solid transparent;
    border-radius: 50%;
    -webkit-animation: 1s spin linear infinite;
    animation: 1s spin linear infinite;
  }
}