.landing-container {
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 50px;
    color: #fff;
  }
  
  .landing-page-title {
    font-size: 8rem;
    font-weight: 500;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    font-style: helvetica;
  }
  
  .landing-page-subtitle {
    font-size: 1.5rem;
    max-width: 600px;
    margin-bottom: 50px;
    opacity: 0.8;
  }

  .feature-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 20px;
    width: 100%;
}

.feature-card {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    text-align: center;
    transition: all 0.3s ease;
    height: 100%;
    width: 100%;
}

.feature-card:hover {
    transform: scale(1.05);
    background: linear-gradient(to right, #a855f7, #4c51bf);
}

.feature-title {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 15px;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.feature-description {
    font-size: 1.2rem;
    color: white;
    margin-bottom: 25px;
}

  
.primary-button {
  background-color: #3498db;
  color: white;
  font-weight: 600;
  padding: 15px 30px;
  border-radius: 20px;
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 1.2rem;
  font-family: 'Helvetica Neue', sans-serif;
  transition: all 0.3s ease;
}

.primary-button:hover {
  background: linear-gradient(to right, #805ad5, #4299e1);
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

  
  
  .button-icon {
    margin-left: 5px;
  }

  .content-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 900px;
    margin: 0 auto;
    padding: 50px 20px;
    text-align: center;
  }

  .footer {
    padding: 20px;
    text-align: center;
    border-top: 1px solid #e9ecef;
}

.footer-text {
    color: #6c757d;
    margin-bottom: 10px;
}

.footer-link {
    color: #6c757d;
}

.link {
    color: #007BFF;
    text-decoration: none;
}

  .logo {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 20px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  }