/* Add to your ChatLine.css */
@keyframes subtle-pulse {
  0% { opacity: 0.9; }
  50% { opacity: 1; }
  100% { opacity: 0.9; }
}

.message-enter {
  animation: messageSlideIn 0.3s ease-out;
}

@keyframes messageSlideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.chat-line-container:hover .action-buttons {
  opacity: 1;
}

.action-buttons {
  transition: opacity 0.2s ease;
  opacity: 0.8;
}